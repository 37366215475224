export const FirebaseConfig = {
	"projectId": "api-7184618068635769263-997657",
	"appId": "1:212504290305:web:7d286af50b801b3d09cc00",
	"databaseURL": "https://api-7184618068635769263-997657-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "api-7184618068635769263-997657.appspot.com",
	"locationId": "europe-central2",
	"apiKey": "AIzaSyCWvQ-Cmw0EEO0IQ69RYvDBtXwlRS1TBRg",
	"authDomain": "api-7184618068635769263-997657.firebaseapp.com",
	"messagingSenderId": "212504290305",
	"measurementId": "G-VWWK9MVVCL"
};